<template>
  <div class="cuisine">
    <h2>Shopping</h2>
    <CuisineGroup
      v-if="recipes?.length && addingIndex === -1"
      v-for="(c, i) in recipes"
      :key="i"
      :cuisine="c"
      @add="startAddingRecipe(i)"
      @edit="(id) => startEditing(id)"
    />

    <div v-if="addingIndex >= 0">
      <p>Adding a {{ recipes![addingIndex].cuisine }} recipe</p>
      <input
        type="string"
        v-model="recipeForm.name"
        placeholder="Recipe name"
        class="name"
      />
      <div v-for="(s, sIndex) in sections" :key="s[0]" class="section">
        <p>{{ s[1].name }}</p>

        <div
          v-for="(ingredient, i) in recipeForm.ingredients.filter(
            (i) => i.section === Number(s[0])
          )"
          class="ingredient"
        >
          <button @click="remove(ingredient.name)">
            <FontAwesomeIcon :icon="faTimesCircle" />
          </button>
          {{ ingredient.name }}
          <button class="move" @click="move(ingredient.name, -1)">
            <FontAwesomeIcon :icon="faArrowAltCircleUp" />
          </button>
          <button class="move" @click="move(ingredient.name, 1)">
            <FontAwesomeIcon :icon="faArrowAltCircleDown" />
          </button>
        </div>

        <input
          ref="inputs"
          type="string"
          @keypress.enter="addDesktop(s[0], $event)"
        />
        <button class="add_ingredient" @click="addMobile(s[0], sIndex)">
          +
        </button>
      </div>

      <ActiveButton class="save" :style="{ background: colour }" @click="save"
        >Save</ActiveButton
      >
    </div>

    <button v-if="addingIndex >= 0" class="back" @click="back">
      <FontAwesomeIcon :icon="faArrowLeft" />
    </button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CuisineGroup from '../components/CuisineGroup.vue'
import ActiveButton from './ActiveButton.vue'
import { colourStore } from '../store'
import {
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faTimesCircle,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons'
import {
  getRecipes,
  editRecipe,
  addRecipe,
  sections,
  Cuisine,
  Recipe,
} from '../api/recipes'

export default defineComponent({
  components: {
    FontAwesomeIcon,
    CuisineGroup,
    ActiveButton,
  },
  setup() {
    const inputs = ref([])
    const recipes = ref<Cuisine[] | null>(null)
    const addingIndex = ref(-1)
    const recipeForm = ref<Recipe>({ id: -1, name: '', ingredients: [] })

    const colour = computed(() => colourStore.state.colour)

    onMounted(async () => {
      recipes.value = await getRecipes()
    })

    function startAddingRecipe(index: number) {
      addingIndex.value = index
    }

    function startEditing(id: number) {
      let recipe
      let index

      recipes.value!.forEach((c, i) => {
        const found = c.recipes.find((r) => r.id === id)
        if (found) {
          recipe = found
          index = i
          return
        }
      })

      recipeForm.value = recipe!
      addingIndex.value = index!
    }

    function addDesktop(sectionId: string, event: Event) {
      if (!(event.target as HTMLInputElement).value.trim()) {
        return
      }

      add(sectionId, (event.target as HTMLInputElement).value)
      ;(event.target as HTMLInputElement).value = ''
    }

    function addMobile(sectionId: string, sectionIndex: number) {
      const input = (
        inputs.value[sectionIndex] as HTMLInputElement
      ).value.trim()

      add(sectionId, input)
      ;(inputs.value[sectionIndex] as HTMLInputElement).value = ''
    }

    function add(sectionId: string, input: string) {
      recipeForm.value.ingredients.push({
        section: Number(sectionId),
        name: input,
      })
    }

    function remove(name: string) {
      recipeForm.value.ingredients = recipeForm.value.ingredients.filter(
        (i) => i.name !== name
      )
    }

    function move(name: string, dir: number) {
      const index = recipeForm.value.ingredients.findIndex(
        (r) => r.name === name
      )
      const ingredient = recipeForm.value.ingredients.splice(index, 1).pop()
      recipeForm.value.ingredients.splice(index + dir, 0, ingredient!)
    }

    async function save() {
      if (!recipeForm.value.name.trim()) {
        return
      }

      if (recipeForm.value.id === -1) {
        saveNewRecipe()
      } else {
        saveExisitingRecipe()
      }
    }

    async function saveNewRecipe() {
      const id = await addRecipe(
        addingIndex.value.toString(),
        recipeForm.value.name,
        recipeForm.value.ingredients
      )

      if (id !== null) {
        recipes.value![addingIndex.value].recipes.push({
          ...recipeForm.value,
          id,
        })

        recipeForm.value = { id: -1, name: '', ingredients: [] }
        addingIndex.value = -1
      }
    }

    async function saveExisitingRecipe() {
      const result = await editRecipe(
        addingIndex.value.toString(),
        recipeForm.value.id.toString(),
        recipeForm.value.name,
        recipeForm.value.ingredients
      )

      if (result) {
        recipeForm.value = { id: -1, name: '', ingredients: [] }
        addingIndex.value = -1
      }
    }

    function back() {
      recipeForm.value = { id: -1, name: '', ingredients: [] }
      addingIndex.value = -1
    }

    return {
      faArrowAltCircleDown,
      faArrowAltCircleUp,
      startAddingRecipe,
      faTimesCircle,
      startEditing,
      faArrowLeft,
      addingIndex,
      recipeForm,
      addDesktop,
      addMobile,
      sections,
      recipes,
      colour,
      remove,
      inputs,
      save,
      move,
      back,
    }
  },
})
</script>

<style lang="sass" scoped>
@import ../vars

.cuisine
  input
    background: transparent
    border: 0
    border-bottom: 1px solid #aaaaaa
    padding: 8px 12px
    outline: none
    color: white

.section
  margin-bottom: 32px

  > p
    margin: 0 0 4px 0

  input
    color: lightgreen

.name
  margin-bottom: 20px

.ingredient
  display: flex
  align-items: center

  &:hover
    button
      opacity: 1

  button
    background: transparent
    height: 30px
    width: 30px
    border-radius: 4px
    border: 0
    display: flex
    justify-content: center
    align-items: center
    opacity: 0

    svg
      width: 100%
      height: 100%
      color: red

  button.move
    svg
      color: white

.save
  width: 144px
  height: 40px
  border: 0
  border-radius: 4px
  font-weight: bold

.back
  background: transparent
  padding: 2px
  position: absolute
  top: 8px
  left: 8px
  border: 0
  width: 24px
  height: 24px

  > svg
    color: white
    width: 100%
    height: 100%

.add_ingredient
  background: 0
  color: white
  border: 1px solid white
  border-radius: 4px
  margin-left: 8px
</style>

<template>
  <button class="cog" @click="openSettings">
    <FontAwesomeIcon :icon="faCog" :color="'#aaaaaa'" @click="openSettings" />
  </button>
  <div class="settings" v-if="settingsOpen">
    <h4>Accent colour</h4>
    <ColorPicker :color="colour" @changeColor="changeColour" />
    <div class="btnRow">
      <button @click="onCancel">Cancel</button>
      <button
        @click="onSave"
        :style="{
          backgroundColor: colour,
          color: luminance(colour) > 0.4 ? '#1E1E1E' : 'white',
        }"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { computed, defineComponent, ref } from 'vue'
import luminance from 'get-relative-luminance'
import 'vue-color-kit/dist/vue-color-kit.css'
import { ColorPicker } from 'vue-color-kit'
import { colourStore } from '../store'

export default defineComponent({
  components: { FontAwesomeIcon, ColorPicker },
  setup() {
    const settingsOpen = ref(false)
    const prevColour = ref('')

    const colour = computed(() => colourStore.state.colour)

    function openSettings() {
      settingsOpen.value = true
      prevColour.value = colourStore.state.colour
    }

    function changeColour(newColour: { hex: string }) {
      colourStore.setState('colour', newColour.hex)
    }

    function onCancel() {
      settingsOpen.value = false
      colourStore.setState('colour', prevColour.value)
    }

    function onSave() {
      settingsOpen.value = false
      window.localStorage.setItem('colour', colourStore.state.colour)
    }

    return {
      settingsOpen,
      openSettings,
      changeColour,
      luminance,
      onCancel,
      colour,
      onSave,
      faCog,
    }
  },
})
</script>

<style lang="sass" scoped>
@import ../vars

.cog
  width: 32px
  height: 32px
  background-color: transparent
  border: 0
  padding: 2px

  position: absolute
  top: 16px
  right: 16px

  svg
    display: block
    width: 100% !important
    height: 100% !important

.settings
  position: absolute
  z-index: 1
  top: 20px
  right: 20px
  padding: 16px
  background-color: $background2
  border: 2px $border solid
  border-radius: 10px
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.4)

  h4
    margin: 0 0 8px

.btnRow
  display: flex
  margin-top: 16px

  button
    flex: 1
    background-color: transparent
    border: 2px white solid
    color: white
    font-weight: bold
    padding: 4px
    border-radius: 6px

    &:first-child
      margin-right: 8px

    &:last-child
      margin-left: 8px
      color: black
</style>

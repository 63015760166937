<template>
  <div class="selector">
    <ActiveButton
      :style="{ border: activeTab === 'todo' ? border : null }"
      :activeStyle="{ border }"
      :hoverStyle="{ border }"
      :focusStyle="{ border }"
      @click="$emit('update:tab', 'todo')"
      >📋</ActiveButton
    >
    <ActiveButton
      :style="{ border: activeTab === 'spending' ? border : null }"
      :activeStyle="{ border }"
      :hoverStyle="{ border }"
      :focusStyle="{ border }"
      @click="$emit('update:tab', 'spending')"
      >💰</ActiveButton
    >
    <ActiveButton
      :style="{ border: activeTab === 'shares' ? border : null }"
      :activeStyle="{ border }"
      :hoverStyle="{ border }"
      :focusStyle="{ border }"
      @click="$emit('update:tab', 'shares')"
      >📈</ActiveButton
    >
    <ActiveButton
      :style="{ border: activeTab === 'weather' ? border : null }"
      :activeStyle="{ border }"
      :hoverStyle="{ border }"
      :focusStyle="{ border }"
      @click="$emit('update:tab', 'weather')"
      >☀️</ActiveButton
    >
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import ActiveButton from './ActiveButton.vue'
import { colourStore } from '../store'

export default defineComponent({
  props: {
    activeTab: {
      type: String,
      default: null,
    },
  },
  components: {
    ActiveButton,
  },
  emits: ['update:tab'],
  setup() {
    const border = computed(() => `2px ${colourStore.state.colour} solid`)
    return {
      border,
    }
  },
})
</script>

<style lang="sass" scoped>
@import ../vars

.selector
  display: none
  gap: 12px

  button
    background: $background2
    border: 2px $border solid
    border-radius: 100%
    padding: 6px 8px
    font-size: 18px
    transition: all 200ms ease

@media screen and (max-width: 767px)
  .selector
    display: flex
</style>

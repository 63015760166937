<template>
  <div>
    <Selector
      :active-tab="activeTab"
      @update:tab="(newTab: string) => (activeTab = newTab)"
    />
    <div className="row" v-show="page === 'home'">
      <div>
        <Bookmarks />
        <div className="row">
          <Banking :class="activeTab === 'spending' ? '' : 'hidden'" />
          <Shares :class="activeTab === 'shares' ? '' : 'hidden'" />
        </div>
        <Todo :class="activeTab === 'todo' ? '' : 'hidden'" />
      </div>
      <div>
        <WeatherClock :class="activeTab === 'weather' ? '' : 'hidden'" />
      </div>
    </div>
    <Shopping v-if="page === 'shopping'" />
    <button class="shopping" @click="toggleShopping()">
      <FontAwesomeIcon :icon="faShoppingCart" :color="'#aaaaaa'" />
    </button>
    <Settings />
  </div>
</template>

<script lang="ts">
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import WeatherClock from './components/WeatherClock.vue'
import Bookmarks from './components/Bookmarks.vue'
import Shopping from './components/Shopping.vue'
import Settings from './components/Settings.vue'
import Selector from './components/Selector.vue'
import Banking from './components/Banking.vue'
import Shares from './components/Shares.vue'
import { defineComponent, ref } from 'vue'
import Todo from './components/Todo.vue'

export default defineComponent({
  name: 'App',
  components: {
    FontAwesomeIcon,
    WeatherClock,
    Bookmarks,
    Selector,
    Settings,
    Shopping,
    Banking,
    Shares,
    Todo,
  },
  setup() {
    const page = ref('home')
    const activeTab = ref('todo')

    let secretKey = localStorage.getItem('secret-key') || ''

    if (!secretKey) {
      secretKey = prompt('Secret key?') || ''
      localStorage.setItem('secret-key', secretKey)
    }

    function toggleShopping() {
      page.value = page.value === 'home' ? 'shopping' : 'home'
    }

    return {
      toggleShopping,
      faShoppingCart,
      activeTab,
      page,
    }
  },
})
</script>

<style lang="sass">
@import ./vars
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap')

*
  box-sizing: border-box

body
  margin: 0
  background-color: $background
  padding: 40px
  color: white
  font-family: 'Nunito', sans-serif !important

button
  cursor: pointer
  font-family: 'Nunito', sans-serif !important

a, button
  outline: none

.row
  display: flex
  align-items: flex-start

  > div
    &:first-child
      flex: 1

    &:last-child
      padding-left: 10px

.shopping
  width: 32px
  height: 32px
  background-color: transparent
  border: 0
  padding: 2px

  position: absolute
  top: 16px
  right: 52px

  svg
    display: block
    width: 100% !important
    height: 100% !important

@media screen and (max-width: 767px)
  body
    padding: 12px

  .hidden
    display: none
</style>

<template>
  <div class="bookmarks">
    <ActiveButton
      class="go"
      :activeStyle="{ border }"
      :hoverStyle="{ border }"
      :focusStyle="{ border }"
      @on-click="go"
    >
      <FontAwesomeIcon :icon="faArrowRight" :color="'white'" />
    </ActiveButton>
    <ActiveButton
      v-for="b in bookmarks"
      :key="b.name"
      class="site"
      :href="b.url"
      :activeStyle="{ border, color: 'white' }"
      :hoverStyle="{ border, color: 'white' }"
      :focusStyle="{ border, color: 'white' }"
    >
      <img :src="b.favicon || `${b.url}/favicon.ico`" :alt="b.name" />
      <span>{{ b.name }}</span>
    </ActiveButton>
  </div>
</template>

<script lang="ts">
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { defineComponent, computed } from 'vue'
import ActiveButton from './ActiveButton.vue'
import { colourStore } from '../store'

const bookmarks = [
  {
    url: 'https://www.facebook.com',
    name: 'Facebook',
  },
  {
    url: 'https://www.mousehuntgame.com',
    name: 'Mousehunt',
  },
  {
    url: 'https://twitter.com',
    name: 'Twitter',
  },
  {
    url: 'https://www.youtube.com/feed/subscriptions',
    name: 'Youtube',
    favicon: 'https://www.youtube.com/s/desktop/d5bd6dd9/img/favicon.ico',
  },
  {
    url: 'https://hotmail.com',
    name: 'Hotmail',
    favicon:
      'https://res.public.onecdn.static.microsoft/owamail/20241018004.17/resources/images/favicons/mail-seen.ico',
  },
  {
    url: 'https://todayin.games',
    name: 'Today in games',
    favicon: 'https://todayin.games/favicon.ico',
  },
  {
    url: 'https://app.todoist.com',
    name: 'Todoist',
  },
  {
    url: 'https://www.ozbargain.com.au/deals',
    name: 'OzBargain',
    favicon: 'https://www.ozbargain.com.au/themes/ozbargain/favicon.ico',
  },
]

export default defineComponent({
  components: { ActiveButton, FontAwesomeIcon },
  setup() {
    const border = computed(() => `2px ${colourStore.state.colour} solid`)

    function go() {
      bookmarks.forEach((b) => window.open(b.url, '_blank'))
    }

    return {
      faArrowRight,
      bookmarks,
      border,
      go,
    }
  },
})
</script>

<style lang="sass" scoped>
@import ../vars
.bookmarks
  display: flex
  flex-flow: row wrap
  align-items: center

.site
  padding: 10px 16px
  border-radius: 16px
  margin-right: 10px
  margin-bottom: 10px
  display: flex
  align-items: center
  color: white
  text-decoration: none

  > img
    margin-right: 10px
    display: block
    width: 16px
    height: 16px

  span
    font-size: 16px
    font-weight: 400

.go
  padding: 10px
  border: 0
  border-radius: 100%
  margin-bottom: 10px
  margin-right: 20px

  > svg
    display: block
    width: 22px
    min-width: 22px
    height: 22px

.site, .go
  background: $background2
  border: 2px $border solid
  transition: all 200ms ease

  &:hover,
  &:focus
    background-color: $border
    border: 2px $accent solid

@media screen and (max-width: 767px)
  .bookmarks
    display: none
</style>

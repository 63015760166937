<template>
  <div class="banking">
    <h2>Spending</h2>
    <h4>Remaining: ${{ remaining }}</h4>
    <div class="chartHolder">
      <LineChart
        :height="400"
        :width="700"
        :chartData="{
          datasets: [
            {
              label: 'Spending',
              data: data,
              fill: false,
              borderColor: colour,
              pointBackgroundColor: colour,
              pointRadius: 4,
            },
            {
              label: 'Reference',
              data: [
                {
                  x: startOfMonth.getTime(),
                  y: budget,
                },
                {
                  x: endOfMonth.getTime(),
                  y: 0,
                },
              ],
              fill: false,
              borderColor: '#666',
              borderDash: [8, 8],
              borderWidth: 1,
              pointRadius: 0,
            },
          ],
        }"
        :options="{
          animation: {
            duration: 0,
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              filter: (a, b, c) => a.datasetIndex === 0,
              callbacks: {
                title: (ctx) => {
                  if (!ctx[0]) return null
                  return ctx[0].raw.label + ': ' + ctx[0].raw.date
                },
                label: (ctx) => '$' + ctx.raw.amount.toFixed(2),
              },
            },
          },
          scales: {
            x: {
              type: 'time',
              min: startOfMonth.getTime(),
              max: endOfMonth.getTime(),
              grid: {
                color: '#252525',
              },
            },
            y: {
              grid: {
                color: '#252525',
              },
            },
          },
          onClick,
        }"
      />
      <ActiveButton
        class="add"
        :style="{ color: colour, border: `2px ${colour} solid` }"
        :activeStyle="{ backgroundColor: colour }"
        @on-mouse-down="addDown = true"
        @click="isAddModalOpen = true"
      >
        <FontAwesomeIcon :icon="faPlus" />
      </ActiveButton>
      <ActiveButton
        class="today"
        :style="{ color: colour, border: `2px ${colour} solid` }"
        :activeStyle="{ backgroundColor: colour }"
        @on-mouse-down="todayDown = true"
        >Today</ActiveButton
      >
      <button
        class="month-btn left"
        :disabled="!isCurrentMonth"
        :style="{ color: colour }"
        @click="() => (month = (month + 11) % 12)"
      >
        <FontAwesomeIcon :icon="faChevronCircleLeft" />
      </button>
      <button
        class="month-btn right"
        :disabled="isCurrentMonth"
        :style="{ color: colour }"
        @click="() => (month = new Date().getMonth())"
      >
        <FontAwesomeIcon :icon="faChevronCircleRight" />
      </button>
    </div>

    <AddBankModal v-if="isAddModalOpen" @close:modal="isAddModalOpen = false" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue'
import { getSpending, skipId, ISpendingData, filterData } from '../api/bank'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Chart, registerables } from 'chart.js'
import ActiveButton from './ActiveButton.vue'
import AddBankModal from './AddBankModal.vue'
import { LineChart } from 'vue-chart-3'
import { colourStore } from '../store'
import 'chartjs-adapter-moment'
import moment from 'moment'
import {
  faPlus,
  faChevronCircleLeft,
  faChevronCircleRight,
} from '@fortawesome/free-solid-svg-icons'

Chart.register(...registerables)

const todayDate = Date.now()

export default defineComponent({
  components: {
    FontAwesomeIcon,
    AddBankModal,
    ActiveButton,
    LineChart,
  },
  setup() {
    const budget = ref(0)
    const month = ref(new Date().getMonth())
    const spending = ref<ISpendingData[]>([])
    const todayDown = ref(false)
    const addDown = ref(false)
    const isAddModalOpen = ref(false)

    const onMouseUp = () => {
      todayDown.value = false
      addDown.value = false
    }

    onMounted(async () => {
      window.addEventListener('mouseup', onMouseUp)

      const bankData = await getSpending()
      if (bankData) {
        budget.value = bankData.budget
        spending.value = filterData(bankData)
      }
    })

    onUnmounted(() => {
      window.removeEventListener('mouseup', onMouseUp)
    })

    const isCurrentMonth = computed(() => month.value === new Date().getMonth())

    const startOfMonth = computed(() =>
      moment()
        .add(isCurrentMonth.value ? 0 : -1, 'month')
        .startOf('month')
        .toDate()
    )
    const endOfMonth = computed(() =>
      moment()
        .add(isCurrentMonth.value ? 0 : -1, 'month')
        .endOf('month')
        .toDate()
    )

    const remaining = computed(() => {
      const totalSpent = spending.value
        .filter(
          (pt) => new Date(pt.attributes.createdAt).getMonth() === month.value
        )
        .reduce(
          (sum, data) => (sum += data.attributes.amount.valueInBaseUnits / 100),
          0
        )
      const rem = Math.round((budget.value + totalSpent) * 100) / 100
      return rem.toFixed(2)
    })

    const getDate = (time: number | string) => {
      return moment(time).format('D/M')
    }

    const colour = computed(() => colourStore.state.colour)

    const data = computed(() => {
      let remainingMoney = budget.value
      return [
        {
          label: 'Budget',
          x: startOfMonth.value.getTime(),
          y: budget.value,
          amount: budget.value,
          date: getDate(startOfMonth.value.getTime()),
        },
        ...spending.value
          .filter(
            (pt) => new Date(pt.attributes.createdAt).getMonth() === month.value
          )
          .map((pt) => {
            return {
              id: pt.id,
              label: pt.attributes.message || pt.attributes.description,
              x: new Date(pt.attributes.createdAt).getTime(),
              y: (remainingMoney =
                Math.round(
                  (remainingMoney +
                    pt.attributes.amount.valueInBaseUnits / 100) *
                    100
                ) / 100),
              amount: Math.abs(pt.attributes.amount.valueInBaseUnits / 100),
              date: getDate(pt.attributes.createdAt),
            }
          }),
        ...(todayDown.value
          ? [
              {
                label: 'Today',
                x: todayDate,
                y: remainingMoney,
                amount: 0,
                date: getDate(todayDate),
              },
            ]
          : []),
      ]
    })

    const onClick = (_: any, activeElements: Array<any>) => {
      if (activeElements.length) {
        const id = activeElements[0].element.$context.raw.id

        if (
          confirm(`Hide "${activeElements[0].element.$context.raw.label}"?`)
        ) {
          skipId(id)
        }
      }
    }

    return {
      faChevronCircleRight,
      faChevronCircleLeft,
      isCurrentMonth,
      isAddModalOpen,
      startOfMonth,
      endOfMonth,
      todayDown,
      remaining,
      spending,
      addDown,
      onClick,
      colour,
      budget,
      faPlus,
      month,
      data,
    }
  },
})
</script>

<style lang="sass" scoped>
@import ../vars

.banking
  max-width: 700px

  > h2
    margin-bottom: 0

  > h4
    margin-top: 0
    text-align: right

.tooltip
  background-color: white
  color: black
  padding: 8px
  min-width: 100px

  h4
    margin: 0 0 8px 0

  table
    width: 100%

  td:last-child
    text-align: right
    padding-left: 4px

.chartHolder
  position: relative

button.today,
button.add
  position: absolute
  top: 0
  border-radius: 16px
  display: flex
  align-items: center
  color: white
  background-color: transparent
  color: $accent
  border: 2px $accent solid
  font-weight: bold

button.today
  right: 0
  padding: 6px 20px

button.add
  right: 88px
  padding: 8px 9px

button.month-btn
  position: absolute
  top: 40px
  border-radius: 16px
  display: flex
  align-items: center
  background-color: transparent
  border: 0
  font-size: 20px

button.month-btn.right
  right: 0px

button.month-btn.left
  right: 30px

button.month-btn:disabled
  opacity: 0.5

@media screen and (max-width: 767px)
  .banking
    width: calc(100vw - 24px)
</style>

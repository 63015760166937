<template>
  <div>
    <h3>Total Market Value: {{ formatPretty(totalMarketValue) }}</h3>
    <h4>
      Total Profit/Loss:
      <span :class="totalProfit > 0 ? 'good' : 'bad'"
        >{{ formatPretty(totalProfit) }} ({{
          format((totalProfit / totalSpent) * 100)
        }}%)</span
      >
    </h4>
  </div>
  <table>
    <thead>
      <tr>
        <th>Code</th>
        <th>Profit / Loss ($)</th>
        <th>Profit / Loss (%)</th>
        <th>Today's Profit ($)</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="share in tableData" :key="share.code">
        <td>{{ share.code }}</td>
        <td :class="share.profit >= 0 ? 'good' : 'bad'">
          {{ format(share.profit) }}
        </td>
        <td :class="share.profit >= 0 ? 'good' : 'bad'">
          {{ format(share.percent) }}%
        </td>
        <td :class="share.dailyChange >= 0 ? 'good' : 'bad'">
          {{ format(share.dailyChange) }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, toRefs } from 'vue'
import { IMyShares, IShareData } from '../api/shares'

const BROKERAGE = 10

const format = (n: number) => {
  return (Math.round(n * 100) / 100).toFixed(2)
}

const formatPretty = (n: number) => {
  return (Math.round(n * 100) / 100).toLocaleString('en-AU', {
    style: 'currency',
    currency: 'AUD',
  })
}

export default defineComponent({
  props: {
    myShares: Object as PropType<IMyShares>,
    shareData: {
      type: Object as PropType<IShareData>,
      required: true,
    },
  },
  setup(props) {
    const { myShares, shareData } = toRefs(props)

    const totalSpent = computed(() => {
      if (myShares.value)
        return myShares.value.shares.reduce(
          (sum, share) =>
            sum + share.amount * share.price + (share.brokerage ?? BROKERAGE),
          0
        )
      else return 0
    })

    const totalMarketValue = computed(() => {
      if (Object.keys(shareData.value).length === 0 || !myShares.value) return 0

      return [...myShares.value.shares, ...myShares.value.drp].reduce(
        (sum, share) => {
          const currentPrice = Number(shareData.value[share.code].price)
          return sum + currentPrice * share.amount
        },
        0
      )
    })

    const totalProfit = computed(
      () => totalMarketValue.value - totalSpent.value
    )

    const tableData = computed(() => {
      const shareCodes = Object.keys(shareData.value).sort()
      return shareCodes.map((code) => {
        const share = shareData.value[code]
        const boughtShares = (myShares.value?.shares || []).filter(
          (s) => s.code === code
        )
        const totalBought = boughtShares.reduce(
          (sum, val) => sum + val.amount,
          0
        )
        const totalSpent = boughtShares.reduce(
          (sum, val) =>
            sum + val.amount * val.price + (val.brokerage ?? BROKERAGE),
          0
        )

        const ytdProfit = share.previous_price * totalBought - totalSpent
        const profit = share.price * totalBought - totalSpent
        const percent = (profit / totalSpent) * 100
        const dailyChange = profit - ytdProfit

        return {
          dailyChange,
          percent,
          profit,
          code,
        }
      })
    })

    return {
      totalMarketValue,
      formatPretty,
      totalProfit,
      totalSpent,
      tableData,
      format,
    }
  },
})
</script>

<style lang="sass" scoped>
@import ./Shares.module
</style>

import { API_URL } from './config'

export interface ITodo {
  id: string
  content: string
  due?: {
    datetime?: string
    date: string
  }
}

export const getTodos = async (): Promise<ITodo[] | null> => {
  try {
    const response = await fetch(`${API_URL}/todo`, {
      headers: { 'X-Secret-Key': localStorage.getItem('secret-key') ?? '' },
    })
    if (response.ok) return response.json()
  } catch (error) {
    console.error(error)
  }

  return null
}

export const checkTodo = async (taskId: string): Promise<boolean> => {
  try {
    const response = await fetch(`${API_URL}/todo/check/${taskId}`, {
      headers: { 'X-Secret-Key': localStorage.getItem('secret-key') ?? '' },
    })
    if (response.ok) return true
    else console.log(await response.text())
  } catch (error) {
    console.error(error)
  }

  return false
}

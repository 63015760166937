import { API_URL } from './config'

interface Ingredient {
  section: number
  name: string
}

export interface Recipe {
  id: number
  name: string
  ingredients: Ingredient[]
}

export interface Cuisine {
  cuisine: string
  recipes: Recipe[]
}

export const getRecipes = async (): Promise<Cuisine[] | null> => {
  try {
    const response = await fetch(`${API_URL}/recipes`, {
      headers: { 'X-Secret-Key': localStorage.getItem('secret-key') ?? '' },
    })
    if (response.ok) return response.json()
  } catch (error) {
    console.error(error)
  }

  return null
}

export const addRecipe = async (
  index: string,
  name: string,
  ingredients: Ingredient[]
): Promise<number | null> => {
  try {
    const formData = {
      index,
      name,
      ingredients,
    }
    const response = await fetch(`${API_URL}/recipes/new`, {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: { 'X-Secret-Key': localStorage.getItem('secret-key') ?? '' },
    })
    if (response.ok) {
      const json = await response.json()
      return Number(json.id)
    }
  } catch (error) {
    console.error(error)
  }

  return null
}

export const editRecipe = async (
  index: string,
  id: string,
  name: string,
  ingredients: Ingredient[]
): Promise<boolean> => {
  try {
    const formData = {
      name,
      ingredients,
    }
    const response = await fetch(`${API_URL}/recipes/edit/${index}/${id}`, {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: { 'X-Secret-Key': localStorage.getItem('secret-key') ?? '' },
    })
    return response.ok
  } catch (error) {
    console.error(error)
  }

  return false
}

export const addToShoppingList = async (id: number): Promise<boolean> => {
  try {
    const response = await fetch(`${API_URL}/go-shopping/${id}`, {
      headers: { 'X-Secret-Key': localStorage.getItem('secret-key') ?? '' },
    })
    return response.ok
  } catch (error) {
    console.error(error)
  }

  return false
}

const SECTIONS = {
  851695: {
    order: 2,
    name: 'Fruit Veg',
  },
  851739: {
    order: 3,
    name: 'Meat',
  },
  851691: {
    order: 4,
    name: 'Deli',
  },
  851731: {
    order: 5,
    name: 'Dairy',
  },
  851715: {
    order: 6,
    name: 'Cereals spreads breads',
  },
  851705: {
    order: 7,
    name: 'Snacks',
  },
  851709: {
    order: 8,
    name: 'Pasta Asian Mexican tins spices',
  },
  133013301: {
    order: 9,
    name: 'Baking and tins',
  },
  851735: {
    order: 10,
    name: 'Medicine, frozen & cleaning',
  },
}

export const sections = Object.entries(SECTIONS).sort(
  (a, b) => a[1].order - b[1].order
)

<template>
  <div class="task">
    <span class="title">{{ todo.content }}</span>
    <span v-if="date" :class="`date ${overdue ? 'overdue' : ''}`">
      <template v-if="date.get('hour') > 0">{{ date.format('h:mma') }}</template
      >{{ overdueText }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ITodo } from '../api/todo'
import { Moment } from 'moment'
import moment from 'moment'

const getOverdueText = (date: Moment | null) => {
  if (!date) return [false, '']

  const overdue = date && date.isBefore(moment())
  const daysOverDue =
    overdue && moment(date).startOf('day').diff(moment(), 'days')

  if (!overdue || daysOverDue === 0) return [overdue, '']

  if (daysOverDue === -1) return [overdue, ' yesterday']

  return [overdue, ' ' + date.format('D/M')]
}

export default defineComponent({
  props: {
    todo: {
      type: Object as PropType<ITodo>,
      required: true,
    },
  },
  setup(props) {
    const date = props.todo.due
      ? moment(props.todo.due.datetime || props.todo.due.date)
      : null
    const [overdue, overdueText] = getOverdueText(date)

    return {
      overdueText,
      overdue,
      date,
    }
  },
})
</script>

<style lang="sass" scoped>
.task
  display: flex
  flex-direction: column

  .title
    font-size: 16px
    line-height: 1
    margin-bottom: 6px

  .date
    font-size: 14px
    line-height: 1

    &.overdue
      color: #ff5a35
</style>

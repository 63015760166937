<template>
  <div class="clock">
    <h1>{{ moment(time).format('h:mma') }}</h1>
    <h2>{{ moment(time).format('dddd, D MMMM') }}</h2>
    <div class="weather" v-if="forecast">
      <div
        v-for="(weather, i) in forecast"
        :key="i"
        :class="i === 0 ? 'today' : ''"
      >
        <span> {{ weather.day.slice(0, 3) }}: </span>
        <span class="icon">{{ getIcon(weather.description) }}</span>
        <span v-if="weather.max">{{ weather.max }} °C</span>
        <span class="rain">{{ weather.rain }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { getWeather, IForecast } from './../api/weather'
import moment from 'moment'

const getIcon = (desc: string) => {
  if (
    desc.includes('mostly sunny') ||
    desc.includes('partly cloudy') ||
    desc.includes('mostly clear')
  )
    return '⛅️'
  if (desc.includes('sunny') || desc.includes('clear')) return '☀️'
  if (desc.includes('cloudy')) return '☁️'
  if (desc.includes('windy')) return '🍃'
  if (desc.includes('hazy') || desc.includes('fog')) return '🌫️'
  if (desc.includes('rain')) return '🌧️'
  if (desc.includes('shower')) return '🌦️'
  if (desc.includes('storm')) return '⛈️'

  console.log(`Unsupported weather desc: ${desc}`)
  return ''
}

export default defineComponent({
  setup() {
    const time = ref(new Date())
    const forecast = ref<IForecast[]>([])

    onMounted(async () => {
      const data = await getWeather()
      if (data) forecast.value = data

      const timer = () => {
        time.value = new Date()
      }
      setInterval(timer, 1000)
    })

    return {
      forecast,
      getIcon,
      moment,
      time,
    }
  },
})
</script>

<style lang="sass" scoped>
.clock
  h1
    margin: 0 0 10px 0
    text-align: right
    font-weight: 500
    font-size: 60px
    line-height: 1

  h2
    margin: 0 0 20px 0
    text-align: right
    font-weight: 500
    line-height: 1

.weather
  > div
    display: flex
    justify-content: flex-end
    align-items: baseline
    margin-bottom: 16px

    > div
      margin: 0 12px

      > span
        display: block
        text-align: center

  *:not(.today) .icon
    font-size: 20px

  .rain
    color: #56aaf8
    margin-left: 10px

  .today
    font-size: 24px
    margin-bottom: 16px

@media screen and (max-width: 767px)
  .clock
    h1
      display: none
    h2
      margin-top: 20px

</style>

import { reactive, readonly } from 'vue'

export const createStore = <T extends Object>(initial: T) => {
  const data = reactive(initial) as T

  return {
    state: readonly(data),
    setState: (key: keyof T, value: T[typeof key]) => {
      data[key] = value
    }
  }
}

export const colourStore = createStore({
  colour: window.localStorage.getItem('colour') ?? '#00bbff',
})

<template>
  <div class="chartHolder">
    <LineChart
      :width="800"
      :height="500"
      :chartData="{
        datasets: [
          {
            label: 'Profit',
            data: chartData,
            fill: false,
            borderColor: colourState.colour,
            pointBackgroundColor: colourState.colour,
            pointRadius: 1,
          },
        ],
      }"
      :options="{
        animation: {
          duration: 0,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            intersect: false,
            mode: 'index',
            callbacks: {
              title: (ctx) => ctx[0].raw.label,
            },
          },
        },
        scales: {
          x: { type: 'time' },
        },
      }"
    />
  </div>
  <div class="ranges">
    <ActiveButton
      class="today"
      @on-click="setRangeIndex(0)"
      :active="rangeIndex === 0"
      :style="style"
      :activeStyle="activeStyle"
    >
      All time
    </ActiveButton>
    <ActiveButton
      class="today"
      @on-click="setRangeIndex(1)"
      :active="rangeIndex === 1"
      :style="style"
      :activeStyle="activeStyle"
    >
      Year
    </ActiveButton>
    <ActiveButton
      class="today"
      @on-click="setRangeIndex(2)"
      :active="rangeIndex === 2"
      :style="style"
      :activeStyle="activeStyle"
    >
      YTD
    </ActiveButton>
    <ActiveButton
      class="today"
      @on-click="setRangeIndex(3)"
      :active="rangeIndex === 3"
      :style="style"
      :activeStyle="activeStyle"
    >
      Month
    </ActiveButton>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import { Chart, registerables } from 'chart.js'
import ActiveButton from './ActiveButton.vue'
import { IShareProfit } from '../api/shares'
import { LineChart } from 'vue-chart-3'
import { colourStore } from '../store'
import 'chartjs-adapter-moment'
import moment from 'moment'

Chart.register(...registerables)

const lastYear = moment().subtract(1, 'year').toDate().getTime()
const ytd = moment().startOf('year').toDate().getTime()
const lastMonth = moment().subtract(1, 'month').toDate().getTime()

export default defineComponent({
  props: {
    profit: {
      type: Object as PropType<IShareProfit[]>,
      required: true,
    },
  },
  components: {
    ActiveButton,
    LineChart,
  },
  setup(props) {
    const rangeIndex = ref(0)

    const chartData = computed(() => {
      if (props.profit.length < 1) return null

      return props.profit
        .filter((pt) => {
          if (rangeIndex.value === 1) return pt.timestamp >= lastYear
          if (rangeIndex.value === 2) return pt.timestamp >= ytd
          if (rangeIndex.value === 3) return pt.timestamp >= lastMonth
          return true
        })
        .map((pt) => {
          return {
            x: pt.timestamp,
            y: pt.profit,
            label: moment(pt.timestamp).format('D/MM/YYYY'),
          }
        })
    })

    const style = computed(() => ({
      color: colourStore.state.colour,
      border: `2px ${colourStore.state.colour} solid`,
    }))

    const activeStyle = computed(() => ({
      backgroundColor: colourStore.state.colour,
    }))

    function setRangeIndex(num: number) {
      rangeIndex.value = num
    }

    return {
      colourState: colourStore.state,
      setRangeIndex,
      activeStyle,
      rangeIndex,
      chartData,
      style,
    }
  },
})
</script>

<style lang="sass" scoped>
@import ./Shares.module
</style>

<template>
  <div class="cuisine">
    <div class="title">
      <p>{{ cuisine.cuisine }}</p>
      <button @click="$emit('add')">
        <FontAwesomeIcon :icon="faPlus" color="white" />
      </button>
    </div>
    <div v-for="r in cuisine.recipes" :key="r.id" class="recipe">
      <span @click="$emit('edit', r.id)">{{ r.name }}</span>

      <div v-if="loadingId === r.id">
        <Loader style="height: 26px" />
      </div>
      <FontAwesomeIcon
        v-else-if="added.includes(r.id)"
        :icon="faCheckCircle"
        color="#31bf31"
        class="check"
      />
      <button v-else @click="addToList(r.id)">
        <FontAwesomeIcon :icon="faPlus" color="white" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { faCheckCircle, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { PropType, defineComponent, ref } from 'vue'
import { addToShoppingList } from '../api/recipes'
import { Cuisine } from '../api/recipes'
import Loader from './Loader.vue'

export default defineComponent({
  components: {
    FontAwesomeIcon,
    Loader,
    faPlus,
  },
  emits: ['add', 'edit'],
  props: {
    cuisine: {
      type: Object as PropType<Cuisine>,
      required: true,
    },
  },
  setup() {
    const added = ref<Number[]>([])
    const loadingId = ref(-1)

    async function addToList(id: number) {
      loadingId.value = id

      const result = await addToShoppingList(id)

      if (result) {
        added.value.push(id)
      } else {
        console.log('FAIL')
      }

      loadingId.value = -1
    }

    return {
      faCheckCircle,
      addToList,
      loadingId,
      faPlus,
      added,
    }
  },
})
</script>

<style lang="sass" scoped>
.cuisine
  max-width: 400px
  margin-bottom: 20px

.title
  display: flex
  justify-content: space-between
  align-items: center
  border-bottom: 1px solid #aaaaaa
  margin-bottom: 10px

  p
    font-size: 20px
    font-weight: bold
    flex: 1
    margin-right: 4px
    margin: 0

  &:hover
    button
      opacity: 1

  button
    background: transparent
    height: 24px
    width: 24px
    border-radius: 4px
    border: 0
    opacity: 0
    display: flex
    justify-content: center
    align-items: center
    background: #179cb0

    &:hover
      background: #24b4c9

.recipe
  display: flex
  justify-content: space-between
  align-items: center

  > span
    cursor: pointer

    &:hover
      text-decoration: underline

  &:hover
    button
      opacity: 1

  button
    background: transparent
    height: 32px
    width: 32px
    border-radius: 4px
    border: 0
    opacity: 0
    display: flex
    justify-content: center
    align-items: center
    background: #31bf31

    &:hover
      background: #41ff41

.check
  width: 24px
  height: 24px
</style>

import { API_URL } from './config'
import moment from 'moment'

export interface ISpendingData {
  id: string
  attributes: {
    amount: {
      valueInBaseUnits: number
    }
    createdAt: string
    description: string
    message: string | null
    foreignAmount: Object | null
  }
  relationships: {
    account: {
      data: {
        id: string
      }
    }
  }
}

export interface ISpending {
  data: ISpendingData[]
  budget: number
  custom: string
  ids_to_skip: string
}

export const getSpending = async (): Promise<ISpending | null> => {
  try {
    const response = await fetch(`${API_URL}/spending`, {
      headers: { 'X-Secret-Key': localStorage.getItem('secret-key') ?? '' },
    })
    if (response.ok) return response.json()
  } catch (error) {
    console.error(error)
  }

  return null
}

export const skipId = async (id: string): Promise<boolean> => {
  try {
    const response = await fetch(`${API_URL}/spending/skip/${id}`, {
      headers: { 'X-Secret-Key': localStorage.getItem('secret-key') ?? '' },
    })
    return response.ok
  } catch (error) {
    console.error(error)
  }

  return false
}

export const addCustom = async (custom: string): Promise<boolean> => {
  try {
    const response = await fetch(`${API_URL}/spending/custom/${custom}`, {
      headers: { 'X-Secret-Key': localStorage.getItem('secret-key') ?? '' },
      method: 'POST',
      body: custom,
    })
    return response.ok
  } catch (error) {
    console.error(error)
  }

  return false
}


const ACCOUNT_ID = '35beffdb-3c62-4dec-97f2-5313d5ba1ae5'

export const filterData = (bankData: ISpending) => {
  const idsToSkip = bankData.ids_to_skip.split(',')
  // idsToSkip = idsToSkip.filter((id) => {
  //   const statement = bankData.data.find((s) => s.id === id)
  //   if (statement) {
  //     const daysSince = moment().diff(statement.attributes.createdAt, 'days')
  //     return daysSince < 65
  //   }
  //   return false
  // })

  const filteredData = bankData.data.filter((data, _, self) => {
    const thisAccount = data.relationships.account.data.id === ACCOUNT_ID
    const outgoing = data.attributes.amount.valueInBaseUnits < 0
    const toSkip = idsToSkip.includes(data.id)
    return (
      thisAccount &&
      outgoing &&
      !toSkip &&
      !data.attributes.foreignAmount &&
      !self.find((other) => {
        // Ignore if matching same day incoming transaction
        const sameValue =
          other.attributes.amount.valueInBaseUnits ===
          Math.abs(data.attributes.amount.valueInBaseUnits)
        const sameAccount = other.relationships.account.data.id === ACCOUNT_ID
        const sameDay =
          moment(other.attributes.createdAt).diff(
            moment(data.attributes.createdAt),
            'day'
          ) === 0
        return sameValue && sameAccount && sameDay
      })
    )
  })

  const additions = bankData.custom.split(',')
  const filteredAdditions = []

  for (let add of additions) {
    const [dateString, amount, desc] = add.split('/')

    const _date = new Date(dateString)
    const daysSince = moment().diff(_date, 'days')
    if (daysSince > 65) {
      continue
    }

    filteredAdditions.push(add)

    filteredData.push({
      attributes: {
        amount: {
          valueInBaseUnits: -Number(amount),
        },
        createdAt: dateString,
        description: desc,
        message: null,
        foreignAmount: null,
      },
      id: '',
      relationships: {
        account: { data: { id: '' } },
      },
    })
  }
  localStorage.setItem('banking_custom', filteredAdditions.join(','))

  filteredData.sort(
    (pt1, pt2) =>
      new Date(pt1.attributes.createdAt).getTime() -
      new Date(pt2.attributes.createdAt).getTime()
  )

  return filteredData
}

import { API_URL } from './config'

export interface IShareData {
  [key: string]: {
    previous_price: number
    price: number
  }
}

export const getShares = async (): Promise<IShareData | null> => {
  try {
    const response = await fetch(`${API_URL}/share-prices`, {
      headers: { 'X-Secret-Key': localStorage.getItem('secret-key') ?? '' },
    })
    if (response.ok) return response.json()
  } catch (error) {
    console.error(error)
  }

  return null
}

export interface IMyShares {
  shares: IMyShare[]
  drp: {
    code: string
    amount: number
  }[]
}

export interface IMyShare {
  code: string
  amount: number
  price: number
  brokerage?: number
}

export const getMyShares = async (): Promise<IMyShares | null> => {
  try {
    const response = await fetch(`${API_URL}/my-shares`, {
      headers: { 'X-Secret-Key': localStorage.getItem('secret-key') ?? '' },
    })
    if (response.ok) return response.json()
  } catch (error) {
    console.error(error)
  }

  return null
}

export interface IShareProfit {
  timestamp: number
  profit: number
}

export const getShareProfit = async (): Promise<IShareProfit[] | null> => {
  try {
    const response = await fetch(`${API_URL}/share-profit`, {
      headers: { 'X-Secret-Key': localStorage.getItem('secret-key') ?? '' },
    })
    if (response.ok) return response.json()
  } catch (error) {
    console.error(error)
  }

  return null
}

import { API_URL } from './config'

interface IWeather {
  time: number
  html: string
}

export interface IForecast {
  day?: string
  description?: string
  max?: string
  rain?: string
}

const dayRegex = /Sunday|Monday|Tuesday|Wednesday|Thursday|Friday|Saturday/g

export const getWeather = async (): Promise<IForecast[] | null> => {
  try {
    const response = await fetch(`${API_URL}/weather`, {
      headers: { 'X-Secret-Key': localStorage.getItem('secret-key') ?? '' },
    })
    if (response.ok) {
      const json = (await response.json()) as IWeather
      if (json) {
        console.log('weather cache:', new Date(json.time))

        const div = document.createElement('div')
        div.innerHTML = json.html

        const forecast: IForecast[] = []
        const days = div.querySelectorAll('.day')
        days.forEach((elem) => {
          const day = elem
            .querySelector('h2')
            ?.textContent?.match(dayRegex)?.[0]
          const description = elem
            .querySelector('.summary')
            ?.textContent?.toLowerCase()
          const max = elem.querySelector('.max')?.innerHTML
          const rain = elem.querySelector('.rain .pop')?.textContent?.trim()
          forecast.push({ day, description, max, rain })
        })

        return forecast
      }

      return null
    }
  } catch (error) {
    console.error(error)
  }

  return null
}

<template>
  <div class="blocker" @click="$emit('close:modal')" />
  <div class="add-modal">
    <div class="form" :style="{ border: `2px ${colour} solid` }">
      <h2>Add Purchase</h2>

      <div class="form-control">
        <label>Date/time</label>
        <input type="datetime-local" v-model="formData.datetime" />
      </div>

      <div class="form-control">
        <label>Description</label>
        <input type="text" v-model="formData.desc" />
      </div>

      <div class="form-control">
        <label>Amount</label>
        <input type="number" v-model="formData.amount" />
      </div>

      <div class="btn-row">
        <button @click="$emit('close:modal')">Cancel</button>
        <button
          :style="{
            backgroundColor: colour,
            color: luminance(colour) > 0.4 ? '#1E1E1E' : 'white',
          }"
          @click="onSave"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, computed, defineComponent } from 'vue'
import luminance from 'get-relative-luminance'
import { addCustom } from '../api/bank'
import { colourStore } from '../store'

export default defineComponent({
  emits: ['close:modal'],
  setup() {
    const formData = ref({
      datetime: '',
      desc: '',
      amount: 0,
    })

    const colour = computed(() => colourStore.state.colour)

    const onSave = async () => {
      if (
        formData.value.datetime &&
        formData.value.desc &&
        formData.value.amount
      ) {
        await addCustom(
          `${new Date(formData.value.datetime).toISOString()}/${
            formData.value.amount * 100
          }/${formData.value.desc}`
        )
        window.location.reload()
      }
    }

    return {
      luminance,
      formData,
      colour,
      onSave,
    }
  },
})
</script>

<style lang="sass" scoped>
@import ../vars

.add-modal,
.blocker
  position: fixed
  z-index: 1
  left: 0
  top: 0
  width: 100%
  height: 100%

.add-modal
  pointer-events: none
  display: flex
  justify-content: center
  align-items: center

.blocker
  background-color: rgba(0, 0, 0, 0.4)

.form
  pointer-events: all
  background: $background
  border: 2px solid white
  border-radius: 10px
  padding: 24px
  min-width: 300px

  h2
    margin-top: 0

  .form-control
    margin-bottom: 20px

    label
      display: block

    input
      width: 100%
      padding: 8px
      border-radius: 4px
      border: 0

.btn-row
  display: flex
  margin-top: 16px

  button
    flex: 1
    background-color: transparent
    border: 2px white solid
    color: white
    font-weight: bold
    padding: 4px
    border-radius: 6px

    &:first-child
      margin-right: 8px

    &:last-child
      margin-left: 8px
      color: black
</style>

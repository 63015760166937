<template>
  <div class="list">
    <h2>To do</h2>
    <Loader v-if="loading" />
    <template v-else>
      <div v-for="todo in todos" :key="todo.id" class="todo">
        <ActiveButton
          @on-click="onCheckOff(todo.id)"
          :hoverStyle="{ border }"
          :focusStyle="{ border }"
        />
        <Task :todo="todo" />
      </div>
      <p v-if="todos.length === 0">No tasks due today</p>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from 'vue'
import { getTodos, ITodo, checkTodo } from './../api/todo'
import ActiveButton from './ActiveButton.vue'
import { colourStore } from '../store'
import Loader from './Loader.vue'
import Task from './Task.vue'
import moment from 'moment'

const filterData = (data: ITodo[]) => {
  return data.sort((a, b) => {
    if (a.due && b.due) {
      const time1 = moment(a.due.datetime || a.due.date)
      if (!a.due.datetime) time1.add(23, 'hours')

      const time2 = moment(b.due.datetime || b.due.date)
      if (!b.due.datetime) time2.add(23, 'hours')

      return time1.unix() - time2.unix()
    }

    return 0
  })
}

export default defineComponent({
  components: {
    ActiveButton,
    Loader,
    Task,
  },
  setup() {
    const loading = ref(true)
    const todos = ref<ITodo[]>([])

    const border = computed(() => `2px ${colourStore.state.colour} solid`)

    onMounted(async () => {
      const data = await getTodos()
      if (data) todos.value = filterData(data)
      loading.value = false
    })

    const onCheckOff = async (taskId: string) => {
      loading.value = true

      const success = await checkTodo(taskId)

      if (success) {
        const todoData = await getTodos()

        if (todoData) {
          todos.value = filterData(todoData)
        }
      }

      loading.value = false
    }

    return {
      onCheckOff,
      loading,
      border,
      todos,
    }
  },
})
</script>

<style lang="sass" scoped>
@import ../vars.scss

.list
  max-width: 700px

.todo
  display: flex
  align-items: flex-start
  border-bottom: 1px $border solid
  padding: 12px 8px

  button
    width: 20px
    height: 20px
    border-radius: 100%
    border: 2px $border solid
    background-color: $background2
    margin-top: 2px
    margin-right: 12px
</style>

<template>
  <a
    v-if="href"
    :href="href"
    target="_blank"
    rel="noopener noreferrer"
    :style="compStyle"
    @mouseover="setHover(true)"
    @mouseleave="setHover(false)"
    @mousedown="onMouseDown"
    @mouseup="setActive(false)"
    @focus="setFocus(true)"
    @blur="setFocus(false)"
    @click="$emit('on-click')"
  >
    <slot />
  </a>
  <button
    v-else
    :style="compStyle"
    @mouseover="setHover(true)"
    @mouseleave="setHover(false)"
    @mousedown="onMouseDown"
    @mouseup="setActive(false)"
    @touchstart="onMouseDown"
    @focus="setFocus(true)"
    @blur="setFocus(false)"
    @click="$emit('on-click')"
  >
    <slot />
  </button>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue'
import luminance from 'get-relative-luminance'
import { colourStore } from '../store'

export default defineComponent({
  props: {
    href: String,
    style: Object,
    activeStyle: Object,
    hoverStyle: Object,
    focusStyle: Object,
    active: Boolean,
  },
  emits: ['on-click', 'on-mouse-down'],
  setup(props, { emit }) {
    const active = ref(false)
    const hover = ref(false)
    const focus = ref(false)

    const onMouseUp = () => (active.value = false)

    onMounted(() => {
      window.addEventListener('mouseup', onMouseUp)
      window.addEventListener('touchend', onMouseUp)
      window.addEventListener('touchcancel', onMouseUp)
    })

    onUnmounted(() => {
      window.removeEventListener('mouseup', onMouseUp)
      window.removeEventListener('touchend', onMouseUp)
      window.removeEventListener('touchcancel', onMouseUp)
    })

    const compStyle = computed(() => {
      const color =
        luminance(colourStore.state.colour) > 0.4 ? '#1E1E1E' : 'white'
      let style = props.style || {}

      if (hover.value && props.hoverStyle)
        style = { ...style, color, ...props.hoverStyle }

      if ((active.value || props.active) && props.activeStyle)
        style = { ...style, color, ...props.activeStyle }

      if (focus.value && props.focusStyle)
        style = { ...style, color, ...props.focusStyle }

      return style
    })

    function onMouseDown() {
      active.value = true
      emit('on-mouse-down')
    }

    return {
      setActive: (a: boolean) => (active.value = a),
      setHover: (h: boolean) => (hover.value = h),
      setFocus: (h: boolean) => (focus.value = h),
      onMouseDown,
      compStyle,
    }
  },
})
</script>

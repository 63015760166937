<template>
  <div class="shares">
    <div class="header">
      <h2>Shares</h2>
      <ActiveButton
        class="go"
        @on-click="switchDisplay()"
        :activeStyle="{ border }"
        :hoverStyle="{ border }"
        :focusStyle="{ border }"
      >
        <FontAwesomeIcon :icon="icon" :color="'white'" />
      </ActiveButton>
    </div>
    <ShareGrid
      v-if="display === DisplayType.Grid"
      :myShares="myShares"
      :shareData="shareData"
    />
    <ShareChart v-if="display === DisplayType.Chart" :profit="shareProfit" />
  </div>
</template>

<script lang="ts">
import { faChartLine, faTable } from '@fortawesome/free-solid-svg-icons'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ActiveButton from './ActiveButton.vue'
import ShareChart from './ShareChart.vue'
import ShareGrid from './ShareGrid.vue'
import { colourStore } from '../store'
import {
  getShareProfit,
  IShareProfit,
  getMyShares,
  IShareData,
  IMyShares,
  getShares,
} from '../api/shares'

enum DisplayType {
  Grid,
  Chart,
}

export default defineComponent({
  components: {
    ActiveButton,
    FontAwesomeIcon,
    ShareGrid,
    ShareChart,
  },
  setup() {
    const display = ref(DisplayType.Grid)
    const myShares = ref<IMyShares>()
    const shareData = ref<IShareData>({})
    const shareProfit = ref<IShareProfit[]>([])

    const icon = computed(() =>
      display.value === DisplayType.Grid ? faChartLine : faTable
    )

    const border = computed(() => `2px ${colourStore.state.colour} solid`)

    onMounted(() => {
      getMyShares().then((data) => {
        if (data) myShares.value = data
      })
      getShares().then((data) => {
        if (data) shareData.value = data
      })
      getShareProfit().then((data) => {
        if (data)
          shareProfit.value = data.toSorted((a, b) => a.timestamp - b.timestamp)
      })
    })

    function switchDisplay() {
      display.value =
        display.value === DisplayType.Grid
          ? DisplayType.Chart
          : DisplayType.Grid
    }

    return {
      switchDisplay,
      DisplayType,
      shareProfit,
      shareData,
      myShares,
      display,
      border,
      icon,
    }
  },
})
</script>

<style lang="sass" scoped>
@import ./Shares.module
</style>
